/* Root */
body {
    border: 0px;
    margin: 0px;
    padding: 0px;
    font-family: Arial, Helvetica, sans-serif;
}

.App {
    display: flex;
}

/* Sidebar */
.hamburger {
    position: absolute;
    left: 0px;
    top: 0px;
    margin: 15px;
    margin-top: 16px;
    z-index: 10;
}

.hamburger img {
    width: 40px;
}

.desktop.open {
    display: flex;
}

.desktop.close {
    display: none;
}

.left-arrow {
    position: absolute;
    right: 0px;
    top: 0px;
    margin: 15px;
    margin-top: 16px;
    z-index: 10;
}

.left-arrow img {
    width: 35px;
}

.mobile {
    position: absolute;
}

.desktop {
    width: 260px;
}

.Sidebar {
    background-color: #202123;
    color: #0da37f;
    height: 100vh;
    flex-shrink: 0;
}

.Sidebar-menu {
    margin: 16px;
    margin-top: 0px;
    text-align: center;
    justify-items: center;
    width: 100%;
}

.Sidebar-menu h2 {
    font-size: 25px;
}

.Sidebar-items {
    display: flex;
    flex-direction: column;
    text-align: start;
}

.side-btn {
    text-decoration: none;
    color: white;
    margin-top: 30px;
    border: 1px solid white;
    border-radius: 3px;
    padding: 10px;
}

.active {
    border: 1px solid #13e3b6;
    color: #13e3b6;
}

.Sidebar-items a:hover {
    color: #0da37f;
    border: 1px solid #0da37f;
}

.Sidebar.open.mobile {
    width: 100vw;
    display: flex;
    transition: width 0.3s ease;
}

.Sidebar.close.mobile {
    width: 0;
    display: flex;
    transition: width 0.3s ease;
}

.Sidebar.close .Sidebar-menu {
    display: none;
}

.left-arrow.open.desktop {
    display: none;
}

.left-arrow.open.mobile {
    display: flex;
}

.highlight {
    background-color: #202123c3;
}

/* Homepage, Population, Crypto, About */
.Content {
    display: flex;
    background-color: #282c34;
    color: white;
    width: 100%;
    height: 100vh;
    overflow-x: hidden;
    align-items: center;
    padding-left: 10px;
    justify-content: center;
    padding-right: 10px;
    overflow-y: auto;
    flex-direction: column;
}

.Content h1 {
    color: #0da37f;
}

/* Crypto Card */
.Crypto {
    justify-content: start;
}

.Crypto.Heading {
    display: flex;
    width: 100%;
    justify-content: center;
    gap: 15px;
    padding: 15px;
    align-items: center;
}

.logo {
    width: 50px;
    height: 50px;
}

.flag {
    width: 50%;
    padding: 15px 0px;
}

.cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    flex-direction: column;
}

.card {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    transition: 0.3s;
    background-color: #202123;
    border: 1px solid #0da37f;
    border-radius: 15px;
    font-size: 20px;
    margin: 10px;
}

.cards-mobile {
    flex-direction: column;
}

.cards-desktop {
    flex-direction: row;
}

.card-desktop {
    width: 30%;
}

.card-mobile {
    width: -webkit-fill-available;
}

.card:hover {
    box-shadow: 0 8px 16px 0 #0da37f55;
    border: 1px solid #13e3b6;
}

.container {
    padding: 2px 16px;
}

.note {
    font-size: 20px;
    color: grey;
}

/* Homepage */
.Homepage {
    font-size: x-large;
}

.button-group {
    display: flex;
    flex-direction: column;
    text-align: center;
    gap: 15px;
}

.button-group a {
    background-color: #0da37f;
    text-decoration: none;
    color: white;
    padding: 10px;
    border-radius: 10px;
}

.button-group a:hover {
    background-color: #0c8b70;
}

/* About */
.About {
    font-size: x-large;
}

.link {
    text-decoration: none;
    color: #0da37f;
}

.link:hover {
    color: #0c8b70;
}